import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Carousel from "react-bootstrap/Carousel";
import AppsIcon from '@material-ui/icons/Apps';
import blueGrey from '@material-ui/core/colors/blueGrey';
import "bootstrap/dist/css/bootstrap.min.css"

import "./portfolioitemdetail.scss"

const PortfolioItemDetail = ({ pageContext }) => {

  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <Layout>
      <SEO
        title={pageContext.yoast.title}
        description={pageContext.yoast.metadesc}
        ogimg={pageContext.yoast.opengraph_image.source_url}
        link={pageContext.link}
      />
      <div className="portfolio-item-detail-wrapper">
        <article className="slideshow-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-1">
                <Link 
                  to={`/portfolio/`} 
                  title="Back to Portfolio" 
                  name="Back to Portfolio"
                >
                  <AppsIcon 
                    fontSize="large" 
                    style={{ color: blueGrey[50] }} 
                  />
                </Link>
              </div>
              <div className="col-10">
                <h2>
                  <Link 
                    to={`/back/`}
                    className="subNavLink prevLink"
                  >
                    &lt;
                  </Link>
                  <Link 
                    to={`../../`}
                  >
                    {pageContext.parent_element.title}
                  </Link>
                  <Link 
                    to={`/forward/`}                    
                    className="subNavLink nextLink"
                  >
                    &gt;
                  </Link>
                </h2>
              </div>
              <div className="col-1">
                &nbsp;
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="carousel-wrapper">
                  <Carousel fade interval={8000}>
                      <Carousel.Item data-pause="true">
                        <img className="d-block w-100" src={pageContext.featured_media.localFile.childImageSharp.fluid.srcWebp} alt={pageContext.title} />
                        <h1 dangerouslySetInnerHTML={{ __html: pageContext.title }} />
                        <Carousel.Caption dangerouslySetInnerHTML={{ __html: pageContext.content }} />
                      </Carousel.Item>
                  </Carousel>
                  <Link 
                      to={`${pageContext.acf.previous_link}`}
                      // className="subNavLink prevLink"
                      className="carousel-control-prev"
                    >
                      <span aria-hidden="true" className="carousel-control-prev-icon"></span>
                      <span className="sr-only">Next</span>
                    </Link>
                    <Link 
                      to={`${pageContext.acf.next_link}`}      
                      //className="subNavLink nextLink"
                      className="carousel-control-next"
                    >
                      <span aria-hidden="true" className="carousel-control-next-icon"></span>
                      <span className="sr-only">Next</span>
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  );
};

export default PortfolioItemDetail;
